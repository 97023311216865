import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useCart } from "react-use-cart";

const ProductAttributes = (props) => {

    const [coupon, setCoupon] = useState();
    const [appliedCoupon, setAppliedCoupon] = useState();
    const { addItem } = useCart();

    useEffect(() => {
        axios.get("/get-coupon")
            .then(resp => {
                console.log(resp.data);
                setCoupon(resp.data?.data?.code);
            })
            .catch(e => {
                console.log(e);
            });

    }, []);

    const successNotify = (v) => toast.success(v, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // progressClassName: "fancy-progress-bar",
      });
      const errorNotify = (v) => toast.error(v, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // progressClassName: "fancy-progress-bar",
      });
      const infoNotify = (v) => toast.info(v, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // progressClassName: "fancy-progress-bar",
      });

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="border-bottom-0">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {props?.modalProductAttributes?.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row justify-content-center">
                        {props?.modalProductAttributes?.attributes && props?.modalProductAttributes?.attributes.map((attribute, index) => {
                            return (
                                <div key={index} className="col-md-4 mb-4">
                                    <div className="product_attribute_cart" onClick={() => {
                                        if (attribute.stock > 0) {
                                            addItem({
                                                id: props?.modalProductAttributes?.id + '-' + attribute.attribute_id,
                                                product_id: props?.modalProductAttributes?.id,
                                                name: props?.modalProductAttributes?.name + " - " + attribute.size + " - " +attribute.color,
                                                price: Number(attribute.final_product_price).toFixed(2),
                                                quantity: 1,
                                                image: props?.modalProductAttributes?.image.small,
                                                stock: attribute.stock,
                                                variation_id: attribute.attribute_id,
                                                variation_final_price: attribute.final_product_price,
                                                variation_stock: attribute.stock,
                                                variation_value: attribute.size + " - " +attribute.color,
                                            });
                                            successNotify('Product added to cart');
                                        } else {
                                            errorNotify('Product is out of stock');
                                        }
                                    }}>
                                        {attribute && isNaN(Number(attribute.discount)) && (<span className="discount_badge">{attribute.discount}</span>)}
                                        <div className="">
                                            <p className="my-2">Size: <b>{attribute.size}</b></p>
                                            {attribute.color && <p className="mb-0">Color: <b>{attribute.color}</b></p>}
                                        </div>
                                        <div className="">
                                            <p className="my-2">{attribute.formatted_final_product_price} {isNaN(Number(attribute.discount)) && (<span className="old-price">{attribute.formatted_regular_price}</span>)}</p>
                                            {attribute.stock > 0 ?
                                                <span className="in_stock">In Stock</span> :
                                                <span className="out_stock">Out Of Stock</span>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-bottom-0 border-top-0 my-2">
                    {/* <Button onClick={props.onHide}>Close</Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProductAttributes;
